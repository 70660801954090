import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

const SlideShow = ({images, interval, autoPlay}) => {
    const imgs = images.map(i => {
      return <div><GatsbyImage image={getImage(i)} alt="Image" /></div>;
    });
    return (
      <Carousel showThumbs={false} infiniteLoop={false} autoPlay={autoPlay} interval={interval}>
        {imgs}
      </Carousel>
    );
}

export default SlideShow;
